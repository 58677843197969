<body [dir]="'ltr'">
  <router-outlet *ngIf="!isLoading"></router-outlet>

  <div class="loading-screen" *ngIf="isLoading">
    <div class="animation-container">
      <!-- Car Parking Animation -->
      <div class="parking-scene">
        <div class="car"></div>
        <div class="parking-spot"></div>
      </div>

      <!-- Finance Management Animation -->
      <div class="finance-scene">
        <div class="finance-icon calculator"></div>
        <div class="finance-icon coins"></div>
        <div class="finance-icon graph"></div>
        <div class="finance-icon document"></div>
      </div>
    </div>

    <p class="loading-text">Loading E-PARK , please wait...</p>
  </div>


  <ngx-spinner type="ball-scale-multiple" color="#FFBF00" bdColor="rgba(0, 0, 0, 0.03)">
  </ngx-spinner>
</body>