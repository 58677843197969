<perfect-scrollbar
  class="top-[2px] z-[1000] fixed border-[#edf2f9] bg-gray-950 dark:bg-[#1b1d2a] custom-scrollbar pr-2 pl-4 dark:border-transparent border-r h-full transition-all duration-200 ease-in-out side-nav"
  sideNav
>
  <ul
    *ngFor="let itemGroup of menuItems"
    class="ant-menu-inline bg-gray-800 dark:bg-[#1b1d2a] border-none rounded-xl text-white ant-menu ant-menu-root side-nav-menu"
    [ngClass]="{
      'ant-menu-inline-collapsed': isFolded,
      'ant-menu-dark': isSideNavDark
    }"
  >
    <li
      [ngClass]="
        item.submenu.length > 0
          ? 'ant-menu-submenu ant-menu-submenu-inline rounded-lg'
          : 'ant-menu-item rounded-lg'
      "
      *ngFor="let item of itemGroup"
      [routerLinkActive]="
        item.submenu.length > 0
          ? 'ant-menu-submenu-open'
          : 'ant-menu-item-selected bg-yellow-500'
      "
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a
        class="text-white"
        class="ant-menu-submenu-title"
        *ngIf="item.submenu.length > 0"
      >
        <i
          class="text-white"
          *ngIf="item.iconType == 'nzIcon'"
          nz-icon
          [nzType]="item.icon"
          [theme]="item.iconTheme"
        ></i>
        <i
          class="text-white"
          *ngIf="item.iconType == 'fontawesome'"
          class="me-[10px]"
          [ngClass]="[item.iconTheme, item.icon]"
        ></i>
        <span class="text-white" [translate]="item.title"> </span>
        <i class="ant-menu-submenu-arrow"></i>
      </a>
      <a
        class="text-white"
        [routerLink]="item.path"
        (click)="closeMobileMenu()"
        *ngIf="item.submenu.length === 0"
      >
        <i
          class="text-white"
          *ngIf="item.iconType == 'nzIcon'"
          nz-icon
          [nzType]="item.icon"
          [theme]="item.iconTheme"
        ></i>
        <i
          class="text-white"
          *ngIf="item.iconType == 'fontawesome'"
          class="me-[10px]"
          [ngClass]="[item.iconTheme, item.icon]"
        ></i>
        <span class="text-white" [translate]="item.title"></span>
      </a>
      <!-- Second Level -->
      <ul
        class="ant-menu-inline bg-gray-800 text-white ant-menu ant-menu-sub dropdown-menu"
        *ngIf="item.submenu.length > 0"
      >
        <li
          [ngClass]="
            subItem.submenu.length > 0
              ? 'ant-menu-submenu ant-menu-submenu-inline rounded-lg'
              : 'ant-menu-item rounded-lg'
          "
          *ngFor="let subItem of item.submenu"
          [routerLinkActive]="
            subItem.submenu.length > 0
              ? 'ant-menu-submenu-open'
              : 'ant-menu-item-selected bg-yellow-500'
          "
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a
            class="text-white ant-menu-submenu-title"
            *ngIf="subItem.submenu.length > 0"
          >
            <i
              class="text-white"
              *ngIf="subItem.iconType == 'nzIcon'"
              nz-icon
              [nzType]="subItem.icon"
              [theme]="subItem.iconTheme"
            ></i>
            <i
              class="text-white"
              *ngIf="subItem.iconType == 'fontawesome'"
              class="me-[10px]"
              [ngClass]="[subItem.iconTheme, subItem.icon]"
            ></i>
            <span class="text-white" [translate]="subItem.title"></span>
            <i class="ant-menu-submenu-arrow"></i>
          </a>
          <a
            [routerLink]="subItem.path"
            (click)="closeMobileMenu()"
            *ngIf="subItem.submenu.length === 0"
          >
            <i
              class="text-white"
              *ngIf="subItem.iconType == 'nzIcon'"
              nz-icon
              [nzType]="subItem.icon"
              [theme]="subItem.iconTheme"
            ></i>
            <i
              *ngIf="subItem.iconType == 'fontawesome'"
              class="text-white me-[10px]"
              [ngClass]="[subItem.iconTheme, subItem.icon]"
            ></i>
            <span class="text-white" [translate]="subItem.title"></span>
          </a>

          <!-- Third Level -->
          <ul
            class="ant-menu-inline bg-gray-800 ant-menu ant-menu-sub dropdown-menu"
            *ngIf="subItem.submenu.length > 0"
          >
            <li
              [ngClass]="
                subItem.submenu.length > 0
                  ? 'ant-menu-submenu ant-menu-submenu-inline rounded-lg'
                  : 'ant-menu-item rounded-lg'
              "
              *ngFor="let subItem of subItem.submenu"
              routerLinkActive="ant-menu-item-selected bg-yellow-500"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a
                class="ant-menu-submenu-title"
                *ngIf="subItem.submenu.length > 0"
              >
                <i
                  class="text-white"
                  *ngIf="subItem.iconType == 'nzIcon'"
                  nz-icon
                  [nzType]="subItem.icon"
                  [theme]="subItem.iconTheme"
                ></i>
                <i
                  c
                  *ngIf="subItem.iconType == 'fontawesome'"
                  class="text-white me-[10px]"
                  [ngClass]="[subItem.iconTheme, subItem.icon]"
                ></i>
                <span class="text-white" [translate]="subItem.title"></span>
                <i class="ant-menu-submenu-arrow"></i>
              </a>
              <a
                [routerLink]="subItem.path"
                (click)="closeMobileMenu()"
                *ngIf="subItem.submenu.length === 0"
              >
                <i
                  class="text-white"
                  *ngIf="subItem.iconType == 'nzIcon'"
                  nz-icon
                  [nzType]="subItem.icon"
                  [theme]="subItem.iconTheme"
                ></i>
                <i
                  *ngIf="subItem.iconType == 'fontawesome'"
                  class="text-white me-[10px]"
                  [ngClass]="[subItem.iconTheme, subItem.icon]"
                ></i>
                <span class="text-white">{{ subItem.title }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</perfect-scrollbar>
