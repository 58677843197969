import { Component } from '@angular/core'

import { ThemeConstantService } from '../../services/theme-constant.service'
import { ROUTES_CUSTOMER_DASHBOARD } from './side-nav-routes.config'
import { L } from '@fullcalendar/list/internal-common'

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent {
  public menuItems: any[] = []
  isFolded: boolean
  isSideNavDark: boolean
  isExpand: boolean

  constructor (private themeService: ThemeConstantService) {}

  ngOnInit (): void {
    //  this.menuItems = ROUTES.filter(menuItem => menuItem);
    // this.menuItems = JSON.parse(localStorage.getItem("CUSTOMER")).type === 'CUSTOMER' ? ROUTES : ROUTES2;
    /* const userData = JSON.parse(localStorage.getItem("user"));
     console.log("User data:", userData);
     if (userData) {
       this.menuItems = [
         ROUTES_CUSTOMER_DASHBOARD.filter((menuItem) => menuItem),
         
       ];
     }*/

    const roles = JSON.parse(localStorage.getItem('user')).roles

    // Extraire toutes les permissions de chaque rôle
    const allPermissions = roles.reduce((acc, role) => {
      // On suppose que chaque rôle a une propriété 'permissions' qui est un tableau
      return acc.concat(role.permissions) // Ajoute les permissions de chaque rôle au tableau d'accumulation
    }, [])

    // Obtenir les permissions uniques
    const uniquePermissions = [...new Set(allPermissions)] // Utilise Set pour garantir l'unicité des éléments

    if (uniquePermissions.includes('VIEW_DASHBOARD')) {
      const adminGroup = {
        path: '/dashboard',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore', // Improved: Represents an overview/dashboard
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_LOCK_PANEL')) {
      const adminGroup = {
        path: '/v1/lock-panel',
        title: 'Lock Panel',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'lock', // Represents security or locking
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (
      uniquePermissions.includes('VIEW_ROLE') ||
      uniquePermissions.includes('VIEW_ADMIN')
    ) {
      const adminGroup = {
        title: 'Administration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting', // Represents general administration or settings
        submenu: []
      }
      if (uniquePermissions.includes('VIEW_ADMIN'))
        adminGroup.submenu.push({
          path: '/v1/admin-list',
          title: 'Administrators',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'usergroup-add', // Represents adding or managing a group of users
          submenu: []
        })

      if (uniquePermissions.includes('VIEW_ROLE'))
        adminGroup.submenu.push({
          path: '/v1/admin-roles',
          title: 'Administration Roles',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'audit', // Represents roles, permissions, or audits,
          submenu: []
        })

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('MANAGE_EVENT')) {
      const adminGroup = {
        title: 'Event management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting', // Represents general administration or settings
        submenu: []
      }
      if (uniquePermissions.includes('MANAGE_EVENT'))
        adminGroup.submenu.push({
          path: '/v1/partner-list',
          title: 'Partners',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'usergroup-add', // Represents adding or managing a group of users
          submenu: []
        })

      if (uniquePermissions.includes('MANAGE_EVENT'))
        adminGroup.submenu.push({
          path: '/v1/event-list',
          title: 'Events',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'audit', // Represents roles, permissions, or audits,
          submenu: []
        })

      if (uniquePermissions.includes('MANAGE_EVENT'))
        adminGroup.submenu.push({
          path: '/v1/event-partner-list',
          title: 'Event Partners',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'audit', // Represents roles, permissions, or audits,
          submenu: []
        })

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_PARKING')) {
      const adminGroup = {
        path: '/v1/parking-list',
        title: 'Parkings',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'car', // Remains relevant for parking management
        submenu: []
      }
      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_PACK')) {
      const adminGroup = {
        path: '/v1/pack-list',
        title: 'Parking Packages',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'gift', // Improved: Represents packages or offers
        submenu: []
      }
      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_USER')) {
      const adminGroup = {
        path: '/v1/customer-list',
        title: 'Customers',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'idcard', // Improved: Represents user/customer profiles
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_NOTIFICATION')) {
      const adminGroup = {
        path: '/v1/notification',
        title: 'Notification',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'notification', // Improved: Represents notification alerts
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_RESERVATION')) {
      const adminGroup = {
        path: '/v1/reservations',
        title: 'Reservations',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'calendar', // Remains relevant for booking/reservations
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (
      uniquePermissions.includes('VIEW_FINANCIAL') ||
      uniquePermissions.includes('VIEW_DEPOSIT') ||
      uniquePermissions.includes('VIEW_INCOME') ||
      uniquePermissions.includes('VIEW_PAYOUT') ||
      uniquePermissions.includes('VIEW_PAYOUT_ACCOUNT') ||
      uniquePermissions.includes('VIEW_PROVIDER')
    ) {
      const adminGroup = {
        title: 'Financial',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dollar', // Improved: Represents financial sections
        submenu: []
      }

      if (uniquePermissions.includes('VIEW_DEPOSIT'))
        adminGroup.submenu.push({
          path: '/v1/Deposits',
          title: 'Deposits',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'bank', // Improved: Represents monetary deposits
          submenu: []
        })

      if (uniquePermissions.includes('VIEW_INCOME'))
        adminGroup.submenu.push({
          path: '/v1/income-list',
          title: 'Incomes',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'rise', // Improved: Represents financial income/growth
          submenu: []
        })

      if (uniquePermissions.includes('VIEW_PAYOUT'))
        adminGroup.submenu.push({
          path: '/v1/payout-list',
          title: 'Payouts',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'dollar-circle', // Remains relevant for payouts
          submenu: []
        })
      if (uniquePermissions.includes('VIEW_PAYOUT_ACCOUNT'))
        adminGroup.submenu.push({
          path: '/v1/payout-accounts',
          title: 'Payout Accounts',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'bank', // Remains relevant for payouts
          submenu: []
        })

      if (uniquePermissions.includes('VIEW_PROVIDER'))
        adminGroup.submenu.push({
          path: '/v1/provider-list',
          title: 'Providers',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'solution', // Improved: Represents service providers
          submenu: []
        })

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_PACK_PURCHASE')) {
      const adminGroup = {
        path: '/v1/pack-purchases',
        title: 'Package Purchases',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shopping-cart', // Represents e-commerce or purchases
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_PLATFORM_SETTINGS')) {
      const adminGroup = {
        path: '/v1/support',
        title: 'Help and Support',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'question-circle', // Improved: Represents help and support
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_SLOT')) {
      const adminGroup = {
        path: '/v1/slot-types',
        title: 'Slot Types',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore', // Improved: Represents categorized slot types
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }
    if (uniquePermissions.includes('VIEW_VEHICLE_TYPE')) {
      const adminGroup = {
        path: '/v1/vehicle-types',
        title: 'Vehicle Types',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore', // Improved: Represents categorized slot types
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_PLATFORM_SETTINGS')) {
      const adminGroup = {
        path: '/v1/platform-setting',
        title: 'Platform Settings',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting', // Remains relevant for settings
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_NOTIFICATION_TYPE')) {
      const adminGroup = {
        path: '/v1/notificationType-list',
        title: 'Notification Type',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'bell', // Improved: Specific to notifications
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    if (uniquePermissions.includes('VIEW_FACILITY')) {
      const adminGroup = {
        path: '/v1/facilities',
        title: 'Facilities',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'build', // Improved: Represents building-related facilities
        submenu: []
      }

      this.menuItems.push([adminGroup])
    }

    console.log('Menu items:', this.menuItems)
    this.themeService.isMenuFoldedChanges.subscribe(
      isFolded => (this.isFolded = isFolded)
    )
    this.themeService.isExpandChanges.subscribe(
      isExpand => (this.isExpand = isExpand)
    )
    this.themeService.isSideNavDarkChanges.subscribe(
      isDark => (this.isSideNavDark = isDark)
    )
  }

  ngAfterViewInit (): void {
    /* Collapsed Menu dropdown */
    let submenus = document.querySelectorAll('.ant-menu li.ant-menu-submenu')
    let direction = document.querySelector('html').getAttribute('dir')
    submenus.forEach(item => {
      item.addEventListener('mouseover', function () {
        let menuItem = this
        let menuItemRect = menuItem.getBoundingClientRect()
        let submenuWrapper = menuItem.querySelector('ul.ant-menu-sub')
        submenuWrapper.style.top = `${menuItemRect.top}px`
        if (direction === 'ltr') {
          submenuWrapper.style.left = `${
            menuItemRect.left + Math.round(menuItem.offsetWidth * 0.75) + 10
          }px`
        } else if (direction === 'rtl') {
          submenuWrapper.style.right = `${
            Math.round(menuItem.offsetWidth * 0.75) + 10
          }px`
        }
      })
    })
  }

  closeMobileMenu (): void {
    if (window.innerWidth < 992) {
      this.isFolded = false
      this.isExpand = !this.isExpand
      this.themeService.toggleExpand(this.isExpand)
      this.themeService.toggleFold(this.isFolded)
    }
  }
}
