import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "./shared/services/shared.service";
import { NzI18nService, ar_EG, en_US } from "ng-zorro-antd/i18n";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  direction: string;
  isLoading = true;
  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private i18n: NzI18nService
  ) {
    localStorage.setItem("language", "en-US");
    translate.setDefaultLang("en-US");
    translate.use("en-US");
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000); // Matches the animation duration
  }
}
