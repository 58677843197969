<div class="top-0 z-[1000] fixed flex bg-black dark:bg-[#323540] border-b dark:border-none w-full min-h-[72px] header">
  <div class="items-center dark:border-transparent h-[71px] transition duration-200 ease-in-out logo logo-dark">
    <a class="block text-center">

    </a>
  </div>
  <div class="flex flex-1 bg-white dark:bg-[#1b1d2a] md:ps-[20px] md:pe-[30px] nav-wrap pe-[15px] ps-[10px]">
    <ul class="nav-left flex items-center gap-x-[14px] gap-y-[9px] mb-0 list-none ps-0">
      <li class="desktop-toggle">
        <a class="flex justify-center items-center bg-transparent hover:bg-primary/10 dark:hover:bg-white/10 rounded-full w-[40px] h-[40px] text-[#525768] hover:text-primary dark:text-white/60 transition duration-200 ease-in-out group"
          (click)="toggleFold()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-folded>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-folded>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <li class="mobile-toggle">
        <a class="flex justify-center items-center bg-transparent hover:bg-primary/10 dark:hover:bg-white/10 rounded-full w-[40px] h-[40px] text-[#525768] hover:text-primary dark:text-white/60 transition duration-200 ease-in-out group"
          (click)="toggleExpand()">
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&.is-expand>svg]:hidden"
            src="assets/images/svg/align-center-alt.svg" />
          <svg-icon [ngClass]="{
              'is-folded': isFolded,
              'is-expand': isExpand
            }" class="[&>svg]:w-[20px] text-current [&>svg]:hidden [&.is-expand>svg]:block"
            src="assets/images/svg/align-left.svg" />
        </a>
      </li>
      <!-- <li>
        <a class="inline-flex max-sm:justify-center items-center gap-[8px] bg-normalBG hover:bg-primary/10 dark:hover:bg-white/60 dark:bg-[#282b37] px-4 max-sm:px-0 rounded-2xl max-sm:w-[34px] min-h-[34px] hover:text-primary dark:hover:text-dark dark:text-white/60 capitalize transition duration-200 group" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="group-hover:text-primary dark:group-hover:text-current max-sm:hidden font-medium text-body text-sm dark:text-white/60">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->
    <app-menu *ngIf="user && user.type == 'CUSTOMER'"></app-menu>
    <!-- desktop -->
    <div class="xl:flex items-center hidden ms-auto">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="nav-right flex flex-auto justify-end items-center gap-y-[15px] sm:gap-x-[25px] max-sm:gap-x-[15px] mb-0 list-none ps-0">

        <li>
          <a class="relative flex items-center" nz-dropdown [nzDropdownMenu]="popNotification" nzTrigger="click">
            <!-- Bell Icon with Badge -->
            <nz-badge [nzStyle]="{ backgroundColor: '#FA8B0C' }" nzDot>
              <svg-icon src="assets/images/svg/bell.svg" class="text-3xl"></svg-icon>
            </nz-badge>
          </a>

          <!-- Dropdown Menu -->
          <nz-dropdown-menu #popNotification="nzDropdownMenu">
            <div
              class="bg-white dark:bg-[#1b1e2b] shadow-lg dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] px-4 py-3 rounded-4 sm:w-[300px] max-sm:w-[230px] md:min-w-[400px]">
              <!-- Dropdown Title -->
              <h1
                class="flex justify-between items-center bg-gray-100 dark:bg-[#32333f] px-4 py-2 rounded-md font-semibold text-dark text-sm dark:text-white">
                <span class="title-text">
                  <span
                    class="inline-flex justify-center items-center bg-warning rounded-full w-5 h-5 text-white text-xs">
                    {{ notifications?.length }}
                  </span>
                </span>
                <span class="font-medium text-gray-600 dark:text-white">Notifications</span>
              </h1>

              <!-- Scrollable Notification List -->
              <perfect-scrollbar>
                <ul class="relative space-y-2 p-0 max-h-[250px] overflow-auto">
                  <li *ngFor="let notification of notifications" class="w-full">
                    <button
                      class="block relative hover:bg-gray-100 dark:hover:bg-[#32333f] hover:shadow-md dark:shadow-none px-4 py-3 rounded-md w-full text-body dark:text-white/80 transition-all group">
                      <div class="flex max-xs:flex-col flex-wrap items-start gap-[15px]">
                        <!-- Icon Placeholder (could be an icon if needed) -->
                        <div class="bg-gray-300 dark:bg-gray-700 rounded-full w-10 h-10"></div>
                      </div>

                      <!-- Notification Content -->
                      <div class="flex flex-1 justify-between items-center">
                        <div class="text-start">
                          <h1
                            class="flex justify-between items-center mb-0.5 font-normal text-[#5a5f7d] text-sm dark:text-white">
                            <span class="font-medium text-primary me-1.5">
                              {{ notification.titleEn }}
                            </span>
                            {{ notification.contentEn }}
                          </h1>
                          <p class="mb-0 text-theme-gray text-xs dark:text-white/60">
                            {{ notification.createdAt | date: 'dd/MM/yyyy hh:mm a' }}
                          </p>
                        </div>
                        <div></div>
                      </div>
                    </button>
                  </li>
                </ul>
              </perfect-scrollbar>
            </div>
          </nz-dropdown-menu>
        </li>



        <li>
          <!-- Profile Dropdown Trigger -->
          <span class="flex items-center text-light whitespace-nowrap cursor-pointer" nz-dropdown
            [nzDropdownMenu]="profile">
            <!-- User Avatar with Icon -->
            <nz-avatar [nzIcon]="'user'"
              nzSrc="https://img.freepik.com/premium-vector/traditional-arabic-man-head-icon-vector-avatar_55610-6301.jpg"
              class="mx-2 rounded-full ring-2 ring-warning hover:ring-4 dark:ring-white w-10 h-10 transition duration-300"></nz-avatar>

        
          </span>

          <!-- Profile Dropdown Menu -->
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <div
              class="bg-white dark:bg-[#1b1e2b] shadow-lg dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] px-6 py-4 pt-4 rounded-4 min-w-[310px] max-sm:min-w-full">
              <!-- Settings Item -->
            

              <!-- Logout Item -->
              <a class="flex justify-between items-center bg-[#f4f5f7] hover:bg-gray-200 dark:hover:bg-[#2b2f38] dark:bg-[#32333f] px-4 py-3 rounded-lg font-medium text-light text-sm hover:text-yellow-600 dark:hover:text-white/60 dark:text-white/[.87] transition duration-300"
                (click)="signOut()">
                <i class="mr-2 text-gray-500 text-xl dark:text-gray-400 fa fa-sign-out-alt"></i>
                <span>Logout</span>
              </a>
            </div>
          </nz-dropdown-menu>
        </li>


      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a class="max-xl:flex items-center hidden text-[18px] text-light dark:text-white/[.87] ms-auto" nz-dropdown
      nzTrigger="click" [nzDropdownMenu]="menu">
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="max-xl:block hidden bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6">
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>