import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ThemeConstantService } from "./services/theme-constant.service";
import { SearchPipe } from "./pipes/search.pipe";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedService } from "./services/shared.service";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    SearchPipe,
   

  ],
  imports: [
    RouterModule,
    CommonModule,
    NgxSpinnerModule,
    NzIconModule,
    NzToolTipModule,
    MatTabsModule,
    NzDatePickerModule,
    TranslateModule,
  ],
  declarations: [SearchPipe,
    
  ],
  providers: [ThemeConstantService, SharedService],
})
export class SharedModule {}
